import React, { useState, useEffect } from 'react';
import './mediaformats.css';
import Video01 from '../assets/waterfall.mp4';
import Video02 from '../assets/waterfall02.mp4';

const MediaFormats = () => {
    const [cooleZahl, setCooleZahl] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let count = 0;
        const endNumber = 14;
        const duration = 2000;
        const intervalTime = duration / endNumber;

        const counterInterval = setInterval(() => {
            if (count < endNumber) {
                count++;
                setCooleZahl(count);
            } else {
                clearInterval(counterInterval);
            }
        }, intervalTime);

        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 86) {
                    clearInterval(interval);
                    return oldProgress;
                }
                return Math.min(oldProgress + 1, 100);
            });
        }, 20);

        return () => {
            clearInterval(counterInterval);
            clearInterval(interval);
        };
    }, []);

    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    const firstDayOfMonth = new Date(year, currentDate.getMonth(), 1).getDay() - 1;
    const adjustedFirstDayOfMonth = firstDayOfMonth === -1 ? 6 : firstDayOfMonth;

    const daysInMonth = new Date(year, currentDate.getMonth() + 1, 0).getDate();

    const totalDays = firstDayOfMonth + daysInMonth;
    const totalWeeks = Math.ceil(totalDays / 7);
    const totalSlots = totalWeeks * 7;


    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const emptyDays = Array(firstDayOfMonth).fill(null);

    return (
        <div className="container">
            <h3><strong>Medien und Formate</strong></h3>
            <p>Diese Seite existiert rein für Analyse-Zwecke. Warum finden Sie unter <a href="/ueberProjekt">Über das Projekt</a>.</p>

            {/* Video Row */}
            <div className="row mb-4">
                <div className="col-md-6">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" src={Video01} autoPlay muted loop controls></video>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" src={Video02} autoPlay muted loop controls></video>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-md-6">
                    <div className="text-center mt-4">
                        <b><p id="coole-zahl" className="coole-zahl">{cooleZahl}</p></b>
                        <p>Coole Zahl</p>
                        <center><div className="swimmer-container">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" className="swimmer-svg">
                                <circle cx="400" cy="400" r="390" stroke="#6EC1E4" strokeWidth="4" fill="none"
                                        className="circle-bg"/>
                                {<path
                                    d="M189.61 310.58c3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c16.02-14.77 34.5-22.58 53.46-22.58h16.3c18.96 0 37.45 7.81 53.46 22.58 3.54 3.26 15.27 9.42 34.39 9.42s30.86-6.16 34.39-9.42c14.86-13.71 31.88-21.12 49.39-22.16l-112.84-80.6 18-12.86c3.64-2.58 8.28-3.52 12.62-2.61l100.35 21.53c25.91 5.53 51.44-10.97 57-36.88 5.55-25.92-10.95-51.44-36.88-57L437.68 98.47c-30.73-6.58-63.02.12-88.56 18.38l-80.02 57.17c-10.38 7.39-19.36 16.44-26.72 26.94L173.75 299c5.47 3.23 10.82 6.93 15.86 11.58zM624 352h-16c-26.04 0-45.8-8.42-56.09-17.9-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C461.8 343.58 442.04 352 416 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C269.8 343.58 250.04 352 224 352s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C77.8 343.58 58.04 352 32 352H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h16c38.62 0 72.72-12.19 96-31.84 23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84h16c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-512-96c44.18 0 80-35.82 80-80s-35.82-80-80-80-80 35.82-80 80 35.82 80 80 80z"
                                    fill="#6EC1E4"/>}
                            </svg>
                        </div></center>
                        <p>Hover over it!</p>
                        <div className="text-center mt-3">
                            <h3 className="progress-title">Ein Balken</h3>
                            <div className="progress-container">
                                <div className="progress-bar" style={{width: `${progress}%`}}>
                                    <span>{progress}%</span>
                                </div>
                            </div><br/><br/><br/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <h4>Kalender</h4>
                    <h3 className="calendar-title">{`${month} ${year}`}</h3>
                    <table className="table calendar-table">
                        <thead>
                        <tr>
                            <th>Mo</th>
                            <th>Di</th>
                            <th>Mi</th>
                            <th>Do</th>
                            <th>Fr</th>
                            <th>Sa</th>
                            <th>So</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from({length: totalWeeks}).map((_, weekIndex) => (
                            <tr key={weekIndex}>
                                {Array.from({length: 7}).map((_, dayIndex) => {
                                    const dayNumber = weekIndex * 7 + dayIndex - adjustedFirstDayOfMonth + 1;
                                    return (
                                        <td key={dayIndex}>
                                            {dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    );
};

export default MediaFormats;
