import React from 'react';
import Nature from '../assets/natur.jpg';

const Home = () => {

    return (
        <div className="container">
            <div className="content">
                <br />
                <h3><strong>Willkommen bei der CO2-Verbrauchsanalyse von Webtechnologien</strong></h3><br />
                <p>Im Rahmen unserer Diplomarbeit haben wir uns einem Projekt gewidmet, das im Zeitalter der Digitalisierung und des Umweltbewusstseins von großer Bedeutung ist: die Analyse des CO2-Verbrauchs verschiedener Webtechnologien. Unser Ziel ist es, einen Einblick zu geben, wie unterschiedliche Technologien wie WordPress, PHP und andere beim Betrieb von Websites in Bezug auf ihre Umweltverträglichkeit abschneiden.</p><br />
                <div className="col">
                    <center><img src={Nature} alt="Bild einer Natur" className="img-fluid" /></center>
                </div>
                <br/><br/>
            </div>
        </div>
    );
};

export default Home;
