import React from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';
import Logo from '../../assets/t_logo.png';
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-dark header-background">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src={Logo} alt="Logo" className="logo" /> {}
                    </NavLink>
                    <span className="site-title d-none d-lg-block">
                        <h4 className="mb-0"><strong>CO2-Rechner Beispielseite</strong></h4>
                        <small>Test-Case "React"</small>
                    </span>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ms-auto">
                            <NavLink className="nav-item nav-link" to="/">Home</NavLink>
                            <NavLink className="nav-item nav-link" to="/ueberProjekt">Über das Projekt</NavLink>
                            <NavLink className="nav-item nav-link" to="/testcases">Test-Cases</NavLink>
                            <NavLink className="nav-item nav-link" to="/mediaformats">Medien und Formate</NavLink>
                            <NavLink className="nav-item nav-link" to="/kontakt">Kontakt</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
