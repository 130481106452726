import React, { useState } from 'react';
import './kontakt.css';

const Kontakt = () => {
    const [formState, setFormState] = useState({
        vorname: '',
        nachname: '',
        email: '',
        betreff: '',
        nachricht: ''
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Formular gesendet mit:', formState);
    };

    return (
        <div className="container">
            <div className="content">
                <h3><strong>Kontakt</strong></h3>

                <div className="row">
                    <div className="col-md-4">
                        <p>
                            Dies ist nur eine Beispiel-Webseite, die das Hinzufügen eines Standorts für Test-Zwecke benötigt.<br /><br />
                            Die hier angeführten <b>Kontaktinformationen existieren nicht!</b>
                        </p>
                        <p>
                            Telnr: <u>+43 1112233444 </u><br />
                            Mail: <u>gibtes.nicht@test.at</u>
                        </p>
                    </div>
                    <div className="col-md-8">
                        <iframe
                            title="Standort"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2648.378904832503!2d15.59061561263797!3d48.410869571155175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4772844583b50efb%3A0xf28099fe9577643f!2sH%C3%B6here%20Technische%20Bundeslehranstalt%20(HTL)%20Krems!5e0!3m2!1sde!2sat!4v1705056445985!5m2!1sde!2sat"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <p><b>Beispiel-Kontaktformular</b></p>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="vorname">Vorname</label>
                            <input type="text" name="vorname" id="vorname" autoComplete="given-name" onChange={handleChange} value={formState.vorname} />

                            <label htmlFor="nachname">Nachname</label>
                            <input type="text" name="nachname" id="nachname" autoComplete="family-name" onChange={handleChange} value={formState.nachname} />

                            <label htmlFor="email">E-Mail</label>
                            <input type="email" name="email" id="email" autoComplete="email" onChange={handleChange} value={formState.email} />

                            <label htmlFor="betreff">Betreff</label>
                            <input type="text" name="betreff" id="betreff" onChange={handleChange} value={formState.betreff} />

                            <label htmlFor="nachricht">Deine Nachricht (optional)</label>
                            <textarea name="nachricht" id="nachricht" onChange={handleChange} value={formState.nachricht}></textarea>

                            <input type="submit" value="Senden" />
                        </form>
                    </div>
                </div>
                <br/><br/>
            </div>
        </div>
    );
};

export default Kontakt;
