import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer-background">
            <div className="container text-center">
                <span className="text-white">© 2024 React Beispiel</span>
            </div>
        </footer>
    );
};

export default Footer;
