import React from 'react';
import { Link } from 'react-router-dom';
import './testcases.css';
import Vid from '../assets/server.mp4';

const TestCases = () => {
    return (
        <div className="container">
            <div className="content">
                <br />
                <h3><strong>Test-Case: React</strong></h3><br />
                <p>Sie befinden sich aktuell auf der Test-Website React, die als Teil unserer umfassenden CO2-Verbrauchsanalyse von Webtechnologien und Tools entwickelt wurde. Dieser Test-Case demonstriert, wie eine typische Info-Seite aufgebaut ist und dient uns als Referenz, um den Energieverbrauch und die CO2-Emissionen, die mit dem Betrieb einer solchen Seite verbunden sind, zu messen.</p><br />
                <div className="col">
                    <div className="embed-responsive embed-responsive-16by9">
                        <video className="embed-responsive-item" src={Vid} autoPlay muted loop controls></video>
                    </div>
                </div>
                <br/>
                <p><strong>Was ist React?</strong><br/>
                    React ist eine JavaScript-Programmbibliothek zur Erstellung von webbasierten Benutzeroberflächen. Komponenten werden in React hierarchisch aufgebaut und können in dessen Syntax als selbst definierte JSX-Tags repräsentiert werden.</p>
                <br />
                <p>Hier ist ein <Link to="/mediaformats">Link</Link>, der Sie zur Seite „Medien und Formate“ bringt. Dieser ist für unsere Analyse ebenso relevant.</p><br />
            </div><br/><br/>
        </div>
    );
};

export default TestCases;
