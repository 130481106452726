import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/home';
import UeberProjekt from './pages/ueberProjekt';
import TestCases from './pages/testcases';
import Mediaformats from './pages/mediaformats';
import Kontakt from './pages/kontakt';

const App = () => {
    useEffect(() => {
        // Setzen der globalen Body-Stile über JavaScript
        document.body.style.margin = "0";
        document.body.style.color = "#7A7A7A";
        document.body.style.fontFamily = "Arial, sans-serif";
        document.body.style.backgroundColor = "#F9F3ED";
        document.body.style.webkitFontSmoothing = "antialiased";
        document.body.style.mozOsxFontSmoothing = "grayscale";
    }, []);

    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ueberProjekt" element={<UeberProjekt />} />
                <Route path="/testCases" element={<TestCases />} />
                <Route path="/mediaformats" element={<Mediaformats />} />
                <Route path="/kontakt" element={<Kontakt />} />
                {}
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
